import React from 'react'
import logo30 from '../assets/logos/30.jpg'
import logo31 from '../assets/logos/31.jpg'
import logo32 from '../assets/logos/32.png'
import logo33 from '../assets/logos/33.jpg'
import logo34 from '../assets/logos/34.png'
import logo35 from '../assets/logos/35.jpg'

const SidthCarouselImage = () => {
    return (
        <div className='first-divider-photo'>
            <img src={logo30}/>
            <img src={logo31}/>
            <img src={logo32}/>
            <img src={logo33}/>
            <img src={logo34}/>
            <img src={logo35}/>
    
        </div>
      )
}

export default SidthCarouselImage