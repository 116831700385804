import React from 'react'
import Carousel from 'react-bootstrap/Carousel';
import FirstCarouselImage from './FirstCarouselImage';
import SecondCarouselImage from './SecondCarouselImage';
import ThirdCarouselImage from './ThirdCarouselImage';
import FourthCarouselImage from './FourthCarouselImage';
import FifthCarouselImage from './FifthCarouselImage';
import SidthCarouselImage from './SidthCarouselImage';
import SeventhCarouselImage from './SeventhCarouselImage';
import EigthCarouselImage from './EigthCarouselImage';
import logo1 from '../assets/logos/1.jpg'
import logo2 from '../assets/logos/2.jpg'
import logo3 from '../assets/logos/3.jpg'
import logo4 from '../assets/logos/4.jpg'
import logo5 from '../assets/logos/5.jpg'
import logo6 from '../assets/logos/6.jpg'
import logo7 from '../assets/logos/7.jpg'
import logo8 from '../assets/logos/8.jpg'
import logo9 from '../assets/logos/9.jpg'
import logo10 from '../assets/logos/10.jpg'
import logo11 from '../assets/logos/11.jpg'
import logo12 from '../assets/logos/12.jpg'
import logo13 from '../assets/logos/13.jpg'
import logo14 from '../assets/logos/14.png'
import logo15 from '../assets/logos/15.png'
import logo16 from '../assets/logos/16.jpg'
import logo17 from '../assets/logos/17.jpg'
import logo18 from '../assets/logos/18.jpg'
import logo19 from '../assets/logos/19.jpg'
// import logo18 from '../assets/logos/18.jpg'
import logo20 from '../assets/logos/20.jpg'
import logo21 from '../assets/logos/21.jpg'
import logo22 from '../assets/logos/22.png'
import logo23 from '../assets/logos/23.jpg'
import logo24 from '../assets/logos/24.jpg'
import logo25 from '../assets/logos/25.jpg'
import logo26 from '../assets/logos/26.jpg'
import logo27 from '../assets/logos/27.jpg'
import logo28 from '../assets/logos/28.jpg'
import logo29 from '../assets/logos/29.jpg'
import logo30 from '../assets/logos/30.jpg'
import logo31 from '../assets/logos/31.jpg'
import logo32 from '../assets/logos/32.png'
import logo33 from '../assets/logos/33.jpg'
import logo34 from '../assets/logos/34.png'
import logo35 from '../assets/logos/35.jpg'
import logo36 from '../assets/logos/36.jpg'
import logo37 from '../assets/logos/37.jpg'
import logo38 from '../assets/logos/38.jpg'
import logo39 from '../assets/logos/39.jpg'
import logo40 from '../assets/logos/40.jpg'
import logo41 from '../assets/logos/41.png'

const Divider = () => {
  const images=[
    {id:1 , value:logo1 ,slide:"First Slider"},
    {id:2 , value:logo2 ,slide:"Second Slider"},
    {id:3 , value:logo3 ,slide:"Third Slider"},
    {id:4 , value:logo4 ,slide:"Fourth Slider"},
    {id:5 , value:logo5 ,slide:"Fifth Slider"},
    {id:6 , value:logo6 ,slide:"Fourth Slider"},
    {id:7 , value:logo7 ,slide:"Fourth Slider"},
    {id:8 , value:logo8 ,slide:"Fourth Slider"},
    {id:9 , value:logo9 ,slide:"Fourth Slider"},
    {id:10 , value:logo10 ,slide:"Fourth Slider"},
    {id:11 , value:logo11 ,slide:"Fourth Slider"},
    {id:12 , value:logo12 ,slide:"Fourth Slider"},
    {id:13 , value:logo13 ,slide:"Fourth Slider"},
    {id:14 , value:logo14 ,slide:"Fourth Slider"},
    {id:15 , value:logo15 ,slide:"Fourth Slider"},
    {id:16 , value:logo16 ,slide:"Fourth Slider"},
    {id:17 , value:logo17 ,slide:"Fourth Slider"},
    {id:18 , value:logo18 ,slide:"Fourth Slider"},
    {id:19 , value:logo19 ,slide:"Fourth Slider"},
    {id:20 , value:logo20 ,slide:"Fourth Slider"},
    {id:21 , value:logo21 ,slide:"Fourth Slider"},
    {id:22 , value:logo22 ,slide:"Fourth Slider"},
    {id:23 , value:logo23 ,slide:"Fourth Slider"},
    {id:24 , value:logo24 ,slide:"Fourth Slider"},
    {id:25 , value:logo25 ,slide:"Fourth Slider"},
    {id:26 , value:logo26 ,slide:"Fourth Slider"},
    {id:27 , value:logo27 ,slide:"Fourth Slider"},
    {id:28 , value:logo28 ,slide:"Fourth Slider"},
    {id:29 , value:logo29 ,slide:"Fourth Slider"},
    {id:30 , value:logo30 ,slide:"Fourth Slider"},
    {id:31 , value:logo31 ,slide:"Fourth Slider"},
    {id:32 , value:logo32 ,slide:"Fourth Slider"},
    {id:33 , value:logo33 ,slide:"Fourth Slider"},
    {id:34 , value:logo34 ,slide:"Fourth Slider"},
    {id:35 , value:logo35 ,slide:"Fourth Slider"},
    {id:36 , value:logo36 ,slide:"Fourth Slider"},
    {id:37 , value:logo37 ,slide:"Fourth Slider"},
    {id:38 , value:logo38 ,slide:"Fourth Slider"},
    {id:39 , value:logo39 ,slide:"Fourth Slider"},
    {id:40 , value:logo40 ,slide:"Fourth Slider"},
    {id:41 , value:logo41 ,slide:"Fourth Slider"}

  ]
  return (
   
    <>
    <Carousel id='ourpartner' className='divider'>
      <Carousel.Item interval={6000}>
        <FirstCarouselImage className="col-xs-11 col-sm-10 col-md-12" text="First slide" />

      </Carousel.Item>
      <Carousel.Item interval={6000}>
        <SecondCarouselImage text="second slide" />

      </Carousel.Item>
      <Carousel.Item interval={6000}>
        <ThirdCarouselImage text="third slide" />

      </Carousel.Item>
      <Carousel.Item interval={6000}>
        <FourthCarouselImage text="Fourth slide" />

      </Carousel.Item>
      <Carousel.Item interval={6000}>
        <FifthCarouselImage text="Fifth slide" />

      </Carousel.Item>
      <Carousel.Item interval={6000}>
        <SidthCarouselImage text="sidth slide" />

      </Carousel.Item>
      <Carousel.Item interval={6000}>
        <SeventhCarouselImage text="seventh slide" />

      </Carousel.Item>
      <Carousel.Item interval={6000}>
        <EigthCarouselImage text="Eigth slide" />

      </Carousel.Item>

    </Carousel>
    
   
        {/* <Carousel id='ourpartner' className='divider2'>
          {
            images.map((data,i)=>
            <Carousel.Item>
            <img
              className="first_divider_photo_slider"
              src={data.value}
              alt={data.slide}
            />
             
            </Carousel.Item>
            
            )
          }
          
        </Carousel> */}
      </>
);
}

export default Divider