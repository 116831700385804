import React from 'react'
import Arrow from '../assets/Arrow 4.svg'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import firstphoto from '../assets/Frame132.png'
import secondphoto from '../assets/Frame 132.png'

const Aboutus = () => {
  return (
    <Row id='about'>
        <Col className='about_col col-md-6 col-sm-6'>
    <div className='aboutus-section'>
       <div className='aboutus-header'> <img src={Arrow}></img> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; About Us</div>
       <div className='aboutus-text-main'>
        <p className='aboutus_text_main'>Mission & Visions</p>
        <br/>
        <p className='aboutus-text-normal col-md-6 col-sm-6'>
        Our Mission: to help you discover the ideal residence or lucrative investment opportunity you've envisioned.
         Whether you're in pursuit of a comfortable family abode,
          a high-yielding investment property,
           or a coveted piece of coastal real estate, we hold the key to your future success.
        </p>
       </div>
       <div className='aboutus-firstphoto'>
        <img className='about_us_firstphoto' src={secondphoto}></img>

       </div>

    </div>
    </Col>
    <Col className='about_col'>
    <div className='aboutus-secondsection'>
    <div className='aboutus-firstphoto'>
        <img className='about_us_secondphoto' src={firstphoto}></img>

       </div>
       <div>
       <p className='aboutus-text-normal2'>
       Our vision: Our vision is to be your trusted real estate partner, 
       leading you towards achieving your goals and aspirations within the property market.
        </p>
       </div>
      
    </div>
    </Col >
    </Row>
  )
}

export default Aboutus