import React from 'react'
import logo7 from '../assets/logos/7.jpg'
import logo8 from '../assets/logos/8.jpg'
import logo9 from '../assets/logos/9.jpg'
import logo10 from '../assets/logos/10.jpg'
import logo11 from '../assets/logos/11.jpg'
import logo12 from '../assets/logos/12.jpg'

const SecondCarouselImage = () => {
    return (
        <div className='first-divider-photo'>
            <img src={logo7}/>
            <img src={logo8}/>
            <img src={logo9}/>
            <img src={logo10}/>
            <img src={logo11}/>
            <img src={logo12}/>
    
        </div>
      )
}

export default SecondCarouselImage