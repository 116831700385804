import React from 'react'
import logo24 from '../assets/logos/24.jpg'
import logo25 from '../assets/logos/25.jpg'
import logo26 from '../assets/logos/26.jpg'
import logo27 from '../assets/logos/27.jpg'
import logo28 from '../assets/logos/28.jpg'
import logo29 from '../assets/logos/29.jpg'

const FifthCarouselImage = () => {
    return (
        <div className='first-divider-photo'>
            <img src={logo24}/>
            <img src={logo25}/>
            <img src={logo26}/>
            <img src={logo27}/>
            <img src={logo28}/>
            <img src={logo29}/>
    
        </div>
      )
}

export default FifthCarouselImage