import React from 'react'
import Arrow from '../assets/Arrow 4.svg'
import Row from 'react-bootstrap/esm/Row'
import Col from 'react-bootstrap/esm/Col'
import Card from 'react-bootstrap/Card';
import cardphoto1 from '../assets/palmhills /logo.png';
import cardphoto2 from '../assets/palmhills /1.jpg';
import cardphoto3 from '../assets/palmhills /2.jpg';
import cardphoto4 from '../assets/palmhills /3.jpeg';
import cardphoto9 from '../assets/palmhills /4.jpg';
import cardphoto5 from '../assets/celopatraplaza /3.jpg';
import cardphoto6 from '../assets/celopatraplaza /logo.jpg';
import cardphoto7 from '../assets/celopatraplaza /1.jpeg';
import cardphoto8 from '../assets/celopatraplaza /2.jpeg';
import cardphoto10 from '../assets/muruj/logo.jpeg';
import cardphoto11 from '../assets/muruj/1.jpg';
import cardphoto12 from '../assets/muruj/2.jpg';
import cardphoto13 from '../assets/muruj/3.jpg';
import cardphoto14 from '../assets/saluga/logo.jpg';
import cardphoto15 from '../assets/saluga/1.jpg';
import cardphoto16 from '../assets/saluga/2.jpg';
import cardphoto17 from '../assets/saluga/3.jpg';
import cardphoto18 from '../assets/sawary/logo.jpg';
import cardphoto19 from '../assets/sawary/1.jpg';
import cardphoto20 from '../assets/sawary/2.jpg';
import cardphoto21 from '../assets/sawary/3.jpg';
import cardphoto22 from '../assets/sawary/4.jpg';
import cardphoto23 from '../assets/skyline/logo.jpg';
import cardphoto24 from '../assets/skyline/1.jpg';
import cardphoto25 from '../assets/skyline/2.jpg';
import cardphoto26 from '../assets/skyline/3.jpg';
import cardphoto27 from '../assets/valore/logo.jpg';
import cardphoto28 from '../assets/valore/1.jpg';
import cardphoto29 from '../assets/valore/2.jpeg';
import { useState, useEffect } from "react";

const Property = () => {
  const [count, setCount] = useState(0);
  const [count1, setCount1] = useState(0);
  const [mousedOver, setMousedOver] = useState(false);
  const images = [
    { spec_id: "1", name: "Image 1", source: cardphoto1 },
    { spec_id: "2", name: "Image 2", source: cardphoto2 },
    { spec_id: "2", name: "Image 3", source: cardphoto4 },
    { spec_id: "4", name: "Image 4", source: cardphoto9 },
    { spec_id: "5", name: "Image 5", source: cardphoto3 }
  ];
  const images1 = [
    { spec_id: "6", name: "Image 6", source: cardphoto6 },
    { spec_id: "7", name: "Image 7", source: cardphoto7 },
    { spec_id: "8", name: "Image 8", source: cardphoto5 },
    { spec_id: "9", name: "Image 9", source: cardphoto8 },
    { spec_id: "7", name: "Image 7", source: cardphoto7 }
  ];
  const images2 = [
    { spec_id: "10", name: "Image 10", source: cardphoto10 },
    { spec_id: "11", name: "Image 11", source: cardphoto11 },
    { spec_id: "12", name: "Image 12", source: cardphoto12 },
    { spec_id: "13", name: "Image 13", source: cardphoto13 },
    { spec_id: "11", name: "Image 11", source: cardphoto11 }
  ];
  const images3 = [
    { spec_id: "14", name: "Image 14", source: cardphoto14 },
    { spec_id: "15", name: "Image 15", source: cardphoto15 },
    { spec_id: "16", name: "Image 16", source: cardphoto16 },
    { spec_id: "17", name: "Image 17", source: cardphoto17 },
    { spec_id: "15", name: "Image 15", source: cardphoto15 }
  ];
  const images4 = [
    { spec_id: "18", name: "Image 18", source: cardphoto18 },
    { spec_id: "19", name: "Image 19", source: cardphoto19 },
    { spec_id: "20", name: "Image 20", source: cardphoto20 },
    { spec_id: "21", name: "Image 21", source: cardphoto21 },
    { spec_id: "22", name: "Image 22", source: cardphoto22 }
  ];
  const images5 = [
    { spec_id: "23", name: "Image 23", source: cardphoto23 },
    { spec_id: "24", name: "Image 24", source: cardphoto24 },
    { spec_id: "25", name: "Image 25", source: cardphoto25 },
    { spec_id: "26", name: "Image 26", source: cardphoto26 },
    { spec_id: "24", name: "Image 24", source: cardphoto24 }
  ];
  const images6 = [
    { spec_id: "27", name: "Image 27", source: cardphoto27 },
    { spec_id: "28", name: "Image 28", source: cardphoto28 },
    { spec_id: "29", name: "Image 29", source: cardphoto29 },
    { spec_id: "28", name: "Image 28", source: cardphoto28 },
    { spec_id: "29", name: "Image 29", source: cardphoto29 },
  ];
  useEffect(() => {
    // set an interval timer if we are currently moused over
    if (mousedOver) {
      const timer = setInterval(() => {
        // cycle prevCount using mod instead of checking for hard-coded length
        setCount((prevCount) => (prevCount + 1) % images.length);
      }, 3000);
      // automatically clear timer the next time this effect is fired or
      // the component is unmounted
      return () => clearInterval(timer);
    } else {
      // otherwise (not moused over), reset the counter
      setCount(0);
    }
    // the dependency on mousedOver means that this effect is fired
    // every time mousedOver changes
  }, [mousedOver]);
  

  return (
    <div className='property-section'>
        <div className='aboutus-header'> <img src={Arrow}></img>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Our Property</div>
        <p>Make your dream project with Future House</p>
    
    <Row className='property-section-firstrow mx-0'>
        <Col>
        <Card className='property-card' onMouseOver={() => setMousedOver(true)} onMouseOut={() => setMousedOver(false)}>
      <Card.Img style={{ height: '371px'}} variant="top" src={images[count].source} />
      <Card.Body className='property-card-body' style={{ height: '115px' }}>
        <Card.Title>Palm Hills</Card.Title>
        <Card.Text>
          
        </Card.Text>
      </Card.Body>
    </Card>
        </Col>
        <Col>
        <Card className='property-card' onMouseOver={() => setMousedOver(true)} onMouseOut={() => setMousedOver(false)}>
      <Card.Img style={{ height: '371px'}} variant="top" src={images1[count].source} />
      <Card.Body className='property-card-body' style={{ height: '115px' }}>
        <Card.Title>Cleopatra</Card.Title>
        <Card.Text>
          
        </Card.Text>
      </Card.Body>
    </Card>
    </Col>
        <Col >
        <Card className='property-card' onMouseOver={() => setMousedOver(true)} onMouseOut={() => setMousedOver(false)}>
      <Card.Img style={{ height: '371px'}} variant="top" src={images2[count].source} />
      <Card.Body className='property-card-body' style={{ height: '115px' }}>
        <Card.Title>Muruj</Card.Title>
        <Card.Text>
          
        </Card.Text>
      </Card.Body>
    </Card>
        </Col>
    </Row>
    <Row className='property-section-firstrow mx-0'>
        <Col>
        <Card className='property-card' onMouseOver={() => setMousedOver(true)} onMouseOut={() => setMousedOver(false)}>
      <Card.Img style={{ height: '371px'}} variant="top" src={images3[count].source} />
      <Card.Body className='property-card-body' style={{ height: '115px' }}>
        <Card.Title>Saluga</Card.Title>
        <Card.Text>
          
        </Card.Text>
      </Card.Body>
    </Card>
        </Col>
        <Col>
        <Card className='property-card' onMouseOver={() => setMousedOver(true)} onMouseOut={() => setMousedOver(false)}>
      <Card.Img style={{ height: '371px'}} variant="top" src={images4[count].source} />
      <Card.Body className='property-card-body' style={{ height: '115px' }}>
        <Card.Title>Sawary</Card.Title>
        <Card.Text>
          
        </Card.Text>
      </Card.Body>
    </Card>
    </Col>
        <Col >
        <Card className='property-card' onMouseOver={() => setMousedOver(true)} onMouseOut={() => setMousedOver(false)}>
      <Card.Img style={{ height: '371px'}} variant="top" src={images5[count].source} />
      <Card.Body className='property-card-body' style={{ height: '115px' }}>
        <Card.Title>Sky Line</Card.Title>
        <Card.Text>
          
        </Card.Text>
      </Card.Body>
    </Card>
        </Col>
    </Row>
    <Row className='property-section-firstrow mx-0'>
        <Col>
        <Card className='property-card' onMouseOver={() => setMousedOver(true)} onMouseOut={() => setMousedOver(false)}>
      <Card.Img style={{ height: '371px'}} variant="top" src={images6[count].source} />
      <Card.Body className='property-card-body' style={{ height: '115px' }}>
        <Card.Title>Valore</Card.Title>
        <Card.Text>
          
        </Card.Text>
      </Card.Body>
    </Card>
        </Col>
        </Row>
        <Row className='property-section-row mx-0'>
                <Col>
                <Card className='property-card' onMouseOver={() => setMousedOver(true)} onMouseOut={() => setMousedOver(false)}>
              <Card.Img style={{ height: '371px'}} variant="top" src={images[count].source} />
              <Card.Body className='property-card-body' style={{ height: '115px' }}>
                <Card.Title>Palm Hills</Card.Title>
                <Card.Text>
                  
                </Card.Text>
              </Card.Body>
            </Card>
                </Col>
                <Col>
                <Card className='property-card' onMouseOver={() => setMousedOver(true)} onMouseOut={() => setMousedOver(false)}>
              <Card.Img style={{ height: '371px'}} variant="top" src={images1[count].source} />
              <Card.Body className='property-card-body' style={{ height: '115px' }}>
                <Card.Title>Cleopatra</Card.Title>
                <Card.Text>
                  
                </Card.Text>
              </Card.Body>
            </Card>
            </Col>
                <Col >
                <Card className='property-card' onMouseOver={() => setMousedOver(true)} onMouseOut={() => setMousedOver(false)}>
              <Card.Img style={{ height: '371px'}} variant="top" src={images2[count].source} />
              <Card.Body className='property-card-body' style={{ height: '115px' }}>
                <Card.Title>Muruj</Card.Title>
                <Card.Text>
                  
                </Card.Text>
              </Card.Body>
            </Card>
                </Col>
                <Col>
                <Card className='property-card' onMouseOver={() => setMousedOver(true)} onMouseOut={() => setMousedOver(false)}>
              <Card.Img style={{ height: '371px'}} variant="top" src={images3[count].source} />
              <Card.Body className='property-card-body' style={{ height: '115px' }}>
                <Card.Title>Saluga</Card.Title>
                <Card.Text>
                  
                </Card.Text>
              </Card.Body>
            </Card>
                </Col>
                <Col>
                <Card className='property-card' onMouseOver={() => setMousedOver(true)} onMouseOut={() => setMousedOver(false)}>
              <Card.Img style={{ height: '371px'}} variant="top" src={images4[count].source} />
              <Card.Body className='property-card-body' style={{ height: '115px' }}>
                <Card.Title>Sawary</Card.Title>
                <Card.Text>
                  
                </Card.Text>
              </Card.Body>
            </Card>
            </Col>
                <Col >
                <Card className='property-card' onMouseOver={() => setMousedOver(true)} onMouseOut={() => setMousedOver(false)}>
              <Card.Img style={{ height: '371px'}} variant="top" src={images5[count].source} />
              <Card.Body className='property-card-body' style={{ height: '115px' }}>
                <Card.Title>Sky Line</Card.Title>
                <Card.Text>
                  
                </Card.Text>
              </Card.Body>
            </Card>
                </Col>
                <Col>
                <Card className='property-card' onMouseOver={() => setMousedOver(true)} onMouseOut={() => setMousedOver(false)}>
              <Card.Img style={{ height: '371px'}} variant="top" src={images6[count].source} />
              <Card.Body className='property-card-body' style={{ height: '115px' }}>
                <Card.Title>Valore</Card.Title>
                <Card.Text>
                  
                </Card.Text>
              </Card.Body>
            </Card>
                </Col>
        </Row>
    </div>
  )
}

export default Property