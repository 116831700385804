import React from 'react'
import logo36 from '../assets/logos/36.jpg'
import logo37 from '../assets/logos/37.jpg'
import logo38 from '../assets/logos/38.jpg'
import logo39 from '../assets/logos/39.jpg'
import logo40 from '../assets/logos/40.jpg'
import logo41 from '../assets/logos/41.png'

const SeventhCarouselImage = () => {
    return (
        <div className='first-divider-photo'>
            <img src={logo36}/>
            <img src={logo37}/>
            <img src={logo38}/>
            <img src={logo39}/>
            <img src={logo40}/>
            <img src={logo41}/>
           
    
        </div>
      )
}

export default SeventhCarouselImage