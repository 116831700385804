import React from 'react'
import logo42 from '../assets/logos/42.jpg'
import logo43 from '../assets/logos/43.jpg'

const EigthCarouselImage = () => {
    return (
        <div className='first-divider-photo'>
            <img src={logo42}/>
            <img src={logo43}/>
            
           
    
        </div>
      )
}

export default EigthCarouselImage