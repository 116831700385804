import React from 'react'
import Row from 'react-bootstrap/esm/Row'
import homelogo from '../assets/Logo.png'

const Secondservice = () => {
  return (
    
        <Row className='w-100 mx-0'>
            <div className='first-service'>
                
                <img src={homelogo}/>
                <p className="text-up">Buy your property </p>
                  <span className='text-span-responsive'>We have obtained a professinal license to directly act as an intermediary in the business of selling, buying and renting real state such as houses , bulidings and offices</span>
                
                <p className='text_inline text-up-second'>Recommended Projets in Alexandria </p>

                  <span>
                  Palm Hills , Saluga , Sawary , Cleopatra , Skyline , Valore
                  </span>
                <a href='https://wa.me/1553382233'>
                <button  className='first-service-button second-button'>Contact Us
                

                </button>
                </a>
            </div>

        </Row>
    
  )
}

export default Secondservice