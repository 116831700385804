import React from 'react'
import logo1 from '../assets/logos/1.jpg'
import logo2 from '../assets/logos/2.jpg'
import logo3 from '../assets/logos/3.jpg'
import logo4 from '../assets/logos/4.jpg'
import logo5 from '../assets/logos/5.jpg'
import logo6 from '../assets/logos/6.jpg'


const FirstCarouselImage = () => {
  return (
    <div className='first-divider-photo'>
        <img src={logo1}/>
        <img src={logo2}/>
        <img src={logo3}/>
        <img src={logo4}/>
        <img src={logo5}/>
        <img src={logo6}/>

    </div>
  )
}

export default FirstCarouselImage