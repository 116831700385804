import React from 'react'
import Arrow from '../assets/Arrow 4.svg'
import quote from '../assets/left-quote 1.svg'
const Testimonial = () => {
  return (
    <div className='testimonial'>
             <div className='testimonial-header'>
                 <img src={Arrow}></img> &nbsp;&nbsp; Our Client</div>
                 
                 <div className='testimonial-header-head'>Testimonial
    </div>
    <div className='testimonial-quote'>
    <img  src={quote} />
    </div>
    <div className='testimonial-text'>
    We have highly-qualified property consultants in our field, We would not exaggerate if we called them" the most cooperative team in the field of real estate marketing".
    </div>
    </div>
  )
}

export default Testimonial
