import React from 'react'
import Row from 'react-bootstrap/esm/Row'
import Col from 'react-bootstrap/esm/Col'
import logo from '../assets/Logo.png'
import { FaFacebookF , FaInstagram , FaLinkedinIn , FaTwitter } from "react-icons/fa";


const Footer = () => {
  return (
    <div className='footer'>
        <Row className='mx-0'>
            <Col className='footer-image' md={2} xs={12}>
                    <img src={logo}/>
            </Col>
            <Col md={2} xs={6}>
            <div className='footer-phone'>
                <div>
                <p className='footer-phone-phone'>Phone</p>
                </div>
                <div>
                <p>+201553382233</p>
                </div>
            </div>
            
            </Col>
            <Col md={3} xs={6}>
            <div className='footer-phone'>
                <div>
                <p className='footer-phone-phone'>Address</p>
                </div>
                <div>
                <p>Compound Saray Nearest To Mosque Ali Ibn Abi Talib ,samouha Alexandria</p>
                </div>
            </div>
            </Col>
            <Col md={2} xs={6}>
            <div className='footer-phone'>
                <div>
                <p className='footer-phone-phone'>Follow&nbsp;&nbsp;&nbsp;Us</p>
                </div>

                <div>
                    <div  className='footer-icons'><a href='https://instagram.com/futurehouse924?igshid=MzRlODBiNWFlZA=='>
                <FaInstagram  /> 
                </a>
                </div>
                <div  className='footer-icons'><a href='https://www.linkedin.com/company/futurehouse-eg/'>
                <FaLinkedinIn /> 
                </a>
                </div>
                <div  className='footer-icons'><a href='https://www.facebook.com/futurehouse.eg?mibextid=LQQJ4d'>
                <FaFacebookF  /> 
                </a>
                </div>
                <div  className='footer-icons'><a href='https://x.com/futurehouse13?s=21&t=lcMnlofcWMXAMnWDeZz_Ew'>
                <FaTwitter  /> 
                </a>
                </div>
                </div>
            </div>
            </Col>
            <Col md={3} xs={6}>
            <div className='footer-phone'>
                <div>
                <p className='footer-phone-phone'>Email</p>
                </div>
                <div>
                <p>info@futurehouseegy.com</p>
                </div>
            </div>
            </Col>
        </Row>
    </div>
  )
}

export default Footer