import React, { useState } from 'react';
import Arrow from '../assets/Arrow 4.svg';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';
import cardphoto1 from '../assets/Alexandria.jpeg'
import cardphoto2 from '../assets/NewCairo.jpeg'
import cardphoto3 from '../assets/newCaptial.jpeg'
import cardphoto4 from '../assets/northcoast.jpeg'
import cardphoto5 from '../assets/6oct.jpeg'

const Slider = () => {
  
  return (
    <div id='ourprojects' className='project-section'>
        <div className='aboutus-header'> <img src={Arrow}></img>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Our Project</div>
        <p>Take look at our seccsesful projects</p>
    
    <Row className='project-section-firstrow'>
        <Col className='my-2'>
        <Card className='project-card' >
          <div className='project-card-overlay'> </div>
          <div className='project-card_content-show '>Alexandria</div>
      <Card.Img  className='project-card-img' style={{ height: '371px'}} variant="top" src={cardphoto1} />
      <div className='project-card_content fadeIn-bottom'>
      
        <p>We have in Alexandria 8 Compounds ,starts from 2,123,000</p>
      </div>
    </Card>
        </Col>
        <Col className='my-2'>
        <Card className='project-card' >
        <div className='project-card-overlay'></div>
        <div className='project-card_content-show '>New Cairo</div>
      <Card.Img className='project-card-img' style={{ height: '371px'}} variant="top" src={cardphoto2} />
      <div className='project-card_content fadeIn-bottom'>
        <p>We have in New Cairo 210 Compounds ,starts from 4,200,000</p>
      </div>
    
    </Card>
    </Col>
        <Col className='my-2'>
        <Card className='project-card' >
          <div className='project-card-overlay'></div>
          <div className='project-card_content-show '>New Captial</div>
      <Card.Img className='project-card-img' style={{ height: '371px'}} variant="top" src={cardphoto3} />
      <div className='project-card_content fadeIn-bottom'>
        <p>We have in New Captial 163 Compounds ,starts from 2,300,000</p>
      </div>
     
    </Card>
        </Col>
        <Col className='my-2'>
        <Card className='project-card' >
          <div className='project-card-overlay'></div>
          <div className='project-card_content-show '>North Coast</div>
      <Card.Img className='project-card-img' style={{ height: '371px'}} variant="top" src={cardphoto4} />
      <div className='project-card_content fadeIn-bottom'>
        <p>We have in North Coast 123 Compounds ,starts from 748,000</p>
      </div>
    
    </Card>
    </Col>
    <Col className='my-2'>
        <Card className='project-card' >
          <div className='project-card-overlay'></div>
          <div className='project-card_content-show '>6 October</div>
      <Card.Img className='project-card-img' style={{ height: '371px'}} variant="top" src={cardphoto5} />
      <div className='project-card_content fadeIn-bottom'>
        <p>We have in <br/>6 October <br/>132 Compounds ,starts from 3,200,000</p>
      </div>
    
    </Card>
    </Col>
    
    </Row>
    
    </div>
    
  )
}

export default Slider;