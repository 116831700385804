import React from 'react'
// import NavBar from '../Components/navbar'
import HeadLine from '../Components/headline'
import Divider from '../Components/divider'
import Aboutus from '../Components/aboutus'
import Firstservice from '../Components/firstservice'
import Property from '../Components/property'
import Secondservice from '../Components/secondservice'
import Footer from '../Components/footer'
import { SiWhatsapp } from "react-icons/si";
import Slider from '../Components/slider'
import Testimonial from '../Components/testimonial'
import NavBar from '../Components/navbar'
import Ceo from '../Components/ceo'

const HomePage = () => {
  return (
    <>
    <NavBar />
    <HeadLine />
    <Divider />
    <Aboutus />
    <Firstservice />
    <Slider/>
    <Property />
    <Secondservice />
    <Ceo />
    <Testimonial/>
    <Footer />
    <div className='fixed-btn'>
      <a href='https://wa.me/1553382233'>
    <SiWhatsapp size={60} color='green' />
    </a>
    </div>
    </>
  )
}

export default HomePage