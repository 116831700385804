import React from 'react'
import Col from 'react-bootstrap/esm/Col'
import Row from 'react-bootstrap/esm/Row'
import BelalAhmed from '../assets/belalahmed.jpeg'
import EslamAdel from '../assets/eslamadel.jpeg'
import EsraaMoussa from '../assets/esraamoussa.jpeg'
import Arrow from '../assets/Arrow 4.svg'
import Container from 'react-bootstrap/esm/Container'
import Image from 'react-bootstrap/esm/Image'
import FayedEzz from '../assets/fayedezz.jpeg'
import GehadAdel from '../assets/gehadadel.jpeg'
import DaliaHassan from '../assets/daliahassan.jpeg'
import DoniaNabil from '../assets/donianabil.jpeg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope , faPhone } from '@fortawesome/free-solid-svg-icons'
const Ceo = () => {

    // const ceoinfo = [
    //     {id:1 , value:BelalAhmed , name: 'Belal  Ahmed' , },
    //     {id:2 , value:EslamAdel , name: 'Eslam Adel' , },
    //     {id:3 , value:EsraaMoussa , name: 'Esraa Moussa' , }
    // ]
  return (
    <>
           <div classname="container-xxl py-5">
            <div classname="container">
                <div classname="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" >
                <div className='aboutus-header'> <img src={Arrow}></img> &nbsp;&nbsp;&nbsp; Our CEOs</div>
       <div className='aboutus-text-main'>
        <p className='aboutus_text_main'>Future&nbsp;House&nbsp;CEOs</p>
        </div>
                    </div>
                <Row classname="row ceo-row-section g={4}">
                    <Col class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                        <div class="team-item ceo-card rounded overflow-hidden" rounded>
                            <div class="position-relative ceo-card-image">
                                <Image class="img-fluid" src={BelalAhmed} alt="" fluid/>
                                <div class="position-absolute start-50 top-100 translate-middle d-flex align-items-center">
                                <a class="btn btn-square mx-1" target='_blank' href="tel:+201556170330"><FontAwesomeIcon icon={faPhone} /></a>    
                                <a class="btn btn-square mx-1" target='_blank' href="https://wa.me/1556170330"><i class="fab fa-whatsapp"></i></a>    
                                <a class="btn btn-square mx-1" target='_blank' href="mailto:Belal.Ahmed@futurehouseegy.com"><FontAwesomeIcon icon={faEnvelope} /></a>
                            </div>
                            </div>
                            <div class="text-center p-4 mt-3">
                                <h4 class="fw-bold mb-0">Belal Ahmed</h4>
                                <medium>Co Founder</medium>
                            </div>
                        </div>
                    </Col>
                    <Col class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                        <div class="team-item ceo-card rounded overflow-hidden" rounded>
                            <div class="position-relative ceo-card-image">
                                <Image class="img-fluid" src={EslamAdel} alt="" fluid/>
                                <div class="position-absolute start-50 top-100 translate-middle d-flex align-items-center">
                                <a class="btn btn-square mx-1" target='_blank' href="tel:+201553382211"><FontAwesomeIcon icon={faPhone} /></a>    
                                <a class="btn btn-square mx-1" target='_blank' href="https://wa.me/1553382211"><i class="fab fa-whatsapp"></i></a>    
                                <a class="btn btn-square mx-1" target='_blank' href="mailto:Eslam.Adel@futurehouseegy.com"><FontAwesomeIcon icon={faEnvelope} /></a>
                            </div>
                            </div>
                            <div class="text-center p-4 mt-3">
                                <h4 class="fw-bold mb-0">Eslam Adel</h4>
                                <medium>CEO</medium>
                            </div>
                        </div>
                    </Col>
                    <Col class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                        <div class="team-item ceo-card rounded overflow-hidden" rounded>
                            <div class="position-relative ceo-card-image">
                                <Image class="img-fluid" src={EsraaMoussa} alt="" fluid/>
                                <div class="position-absolute start-50 top-100 translate-middle d-flex align-items-center">
                                <a class="btn btn-square mx-1" target='_blank' href="tel:+201553382233"><FontAwesomeIcon icon={faPhone} /></a>    
                                <a class="btn btn-square mx-1" target='_blank' href="https://wa.me/1553382233"><i class="fab fa-whatsapp"></i></a>    
                                <a class="btn btn-square mx-1" target='_blank' href="mailto:Esraa.Moussa@futurehouseegy.com"><FontAwesomeIcon icon={faEnvelope} /></a>
                            </div>
                            </div>
                            <div class="text-center p-4 mt-3">
                                <h4 class="fw-bold mb-0">Esraa Moussa</h4>
                                <medium>Co Founder</medium>
                            </div>
                        </div>
                    </Col>
                   
                        
                </Row>
            </div>
        </div>
        <div class="container-xxl py-5" id='ourteam'>
        <div class="container second-container">
            <div class="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s">
                <h1 class="mb-3">Meet Our Team</h1>
            </div>
            <div class="row g-4">
                <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                    <div class="team-item rounded overflow-hidden">
                        {/* <div class="position-relative">
                            <img class="img-fluid" src={FayedEzz} alt=""/>
                            <div class="position-absolute start-50 top-100 translate-middle d-flex align-items-center">
                                <a class="btn btn-square mx-1" target='_blank' href="https://www.facebook.com/profile.php?id=100076103057535&mibextid=ZbWKwL"><i class="fab fa-facebook-f"></i></a>
                                <a class="btn btn-square mx-1" target='_blank' href="https://www.linkedin.com/in/fayed-ezz-916653266?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"><i class="fab fa-linkedin"></i></a>
                                <a class="btn btn-square mx-1" target='_blank' href="mailto:Fayed.Ezz@futurehouseegy.com"><FontAwesomeIcon icon={faEnvelope} /></a>
                                <a class="btn btn-square mx-1" target='_blank' href="https://wa.me/1553382200"><i class="fab fa-whatsapp"></i></a>
                            </div>
                        </div> */}
                        {/* <div class="text-center p-4 mt-3">
                            <h5 class="fw-bold mb-0">Fayed Ezz</h5>
                            <small>Team Leader</small>
                        </div> */}
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                    <div class="team-item rounded overflow-hidden">
                        <div class="position-relative">
                            <img class="img-fluid" src={GehadAdel} alt=""/>
                            <div class="position-absolute start-50 top-100 translate-middle d-flex align-items-center">
                                <a class="btn btn-square mx-1" target='_blank' href="https://www.facebook.com/profile.php?id=100087568852128&mibextid=ZbWKwL"><i class="fab fa-facebook-f"></i></a>
                                <a class="btn btn-square mx-1" target='_blank' href="https://www.linkedin.com/in/gehad-adel-185642189?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app"><i class="fab fa-linkedin"></i></a>
                                <a class="btn btn-square mx-1" target='_blank' href="mailto:Gehad.Adel@futurehouseegy.com"><FontAwesomeIcon icon={faEnvelope} /></a>
                                <a class="btn btn-square mx-1" target='_blank' href="https://wa.me/1552128138"><i class="fab fa-whatsapp"></i></a>
                            </div>
                        </div>
                        <div class="text-center p-4 mt-3">
                            <h5 class="fw-bold mb-0">Gehad Adel</h5>
                            <small>Team Leader</small>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                    <div class="team-item rounded overflow-hidden">
                    <div class="position-relative">
                            <img class="img-fluid" src={DoniaNabil} alt=""/>
                            <div class="position-absolute start-50 top-100 translate-middle d-flex align-items-center">
                                <a class="btn btn-square mx-1" target='_blank' href="https://www.facebook.com/profile.php?id=100083565542450&mibextid=ZbWKwL"><i class="fab fa-facebook-f"></i></a>
                                <a class="btn btn-square mx-1" target='_blank' href="https://www.linkedin.com/in/donia-mohamed-a39569249?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"><i class="fab fa-linkedin"></i></a>
                                <a class="btn btn-square mx-1" target='_blank' href="mailto:Donia.Nabil@futurehouseegy.com"><FontAwesomeIcon icon={faEnvelope} /></a>
                                <a class="btn btn-square mx-1" target='_blank' href="https://wa.me/1552128381"><i class="fab fa-whatsapp"></i></a>
                            </div>
                        </div>
                        <div class="text-center p-4 mt-3">
                            <h5 class="fw-bold mb-0">Donia Nabil</h5>
                            <small>Team Leader</small>
                        </div>
                        
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.7s">
                    <div class="team-item rounded overflow-hidden">
                    {/* <div class="position-relative">
                            <img class="img-fluid" src={DaliaHassan} alt=""/>
                            <div class="position-absolute start-50 top-100 translate-middle d-flex align-items-center">
                                <a class="btn btn-square mx-1" type='disapled' target='_blank' href=""><i class="fab fa-facebook-f"></i></a>
                                <a class="btn btn-square mx-1" target='_blank' href="https://www.linkedin.com/in/dalia-hassan-a627201b4?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"><i class="fab fa-linkedin"></i></a>
                                <a class="btn btn-square mx-1" target='_blank' href="mailto:Dalia.Hassan@futurehouseegy.com"><FontAwesomeIcon icon={faEnvelope} /></a>
                                <a class="btn btn-square mx-1" target='_blank' href="https://wa.me/1555756478"><i class="fab fa-whatsapp"></i></a>
                            </div>
                        </div> */}
                        {/* <div class="text-center p-4 mt-3">
                            <h5 class="fw-bold mb-0">Dalia Hassan</h5>
                            <small>Sales Admin</small>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default Ceo