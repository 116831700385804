import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import headlinephoto1 from '../assets/1.jpeg'
import headlinephoto2 from '../assets/2.jpeg'
import headlinephoto3 from '../assets/3.jpeg'
import headlinephoto4 from '../assets/4.jpeg'

const HeadLine = () => {
  return (
   <>
    <Row className='row_headline'>
        <Col lg={4} className='headline-text '>
            <div className='text_bold'>
            <span  className='headline-text-bold '>Who <br/>We  <br/>Are?</span>
            <br/>
            <br/>
            </div>
            <div className='text_normal' >
            <span className='headline-text-normal'>We are a dedicated team of seasoned real estate professionals with extensive experience in the local market. Our profound expertise in Alexandria, New Cairo, and the North Coast is unmatched. We possess a deep understanding of the unique characteristics of these regions, encompassing Alexandria's rich historical appeal, the rapid development of New Cairo, and the irresistible charm of the North Coast. </span>
            </div>
        </Col>
        <Col className='headline-photos' lg={8}>
            <Row>
            <Col  className='headline-image col-2'>
            <img className='headline_image headline_image_first ' src={headlinephoto1}/>

        </Col>
        <Col  className='headline-secondimage col-2'>
            <img className='headline_image headline_image_second' src={headlinephoto2}/>
        </Col>
        <Col  className='headline-thirdimage col-2'>
            <img className='headline_image headline_image_second headline_image_third' src={headlinephoto3}/>
        </Col>
        <Col  className='headline-fourthimage col-2'>
            <img className='headline_image headline_image_second headline_image_fourth' src={headlinephoto4}/>
        </Col>
            </Row>

        </Col>
        </Row>
        <div className='responsive-header'>
            <div className='responsive-text_bold'>
            <span  className='headline-text-bold '>Who We Are?</span>
            <br/>
            <br/>
            </div> 
            <div className='responsive-text_normal' >
            <span className='headline-text-normal'>We are a dedicated team of seasoned real estate professionals with extensive experience in the local market. Our profound expertise in Alexandria, New Cairo, and the North Coast is unmatched. We possess a deep understanding of the unique characteristics of these regions, encompassing Alexandria's rich historical appeal, the rapid development of New Cairo, and the irresistible charm of the North Coast. </span>
            </div> 
        </div>
        </>  
  )
}

export default HeadLine