
import './App.css';
import HomePage from './Pages/homepage';

function App() {
  return (
    <>
      <HomePage/>
    </>
  );
}

export default App;
