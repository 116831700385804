import React from 'react'
import logo19 from '../assets/logos/19.jpg'
import logo18 from '../assets/logos/18.jpg'
import logo20 from '../assets/logos/20.jpg'
import logo21 from '../assets/logos/21.jpg'
import logo22 from '../assets/logos/22.png'
import logo23 from '../assets/logos/23.jpg'

const FourthCarouselImage = () => {
    return (
        <div className='first-divider-photo'>
            <img src={logo19}/>
            <img src={logo18}/>
            <img src={logo20}/>
            <img src={logo21}/>
            <img src={logo22}/>
            <img src={logo23}/>
    
        </div>
      )
}

export default FourthCarouselImage