import React, { useState } from 'react'
import Logo from "../assets/Logo.png"
import { Link, Outlet } from 'react-router-dom'
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';
import 'bootstrap'



const NavBar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  return (
    <div className='tnavbar'>
<>
<nav className="navbar ">
  <div  className="container">
    <div className='logo-navbar'>
    <a className="navbar-brand" to="home">
   
   <img src= {Logo} alt="Bootstrap" width="120" height="120"/>
 </a>
 
    </div>
    <div className='text-navbar'>
    <a className="navbar-brand nav-content sentex2" href='#about'>About US</a>
    <a className="navbar-brand nav-content sentex2" href='#ourpartner'>Our Partner</a>
    <a className="navbar-brand nav-content sentex2" href='#ourservice'>Our Service</a>
    <a className="navbar-brand nav-content sentex2" href='#ourproject'>Our Project</a>
    <a className="navbar-brand nav-content sentex" href='#ourteam'>Our Team</a>
    </div>
    
    </div>
    <div className="gpt3__navbar-menu">
        {toggleMenu
          ? <RiCloseLine color="#B09547" size={27} onClick={() => setToggleMenu(false)} />
          : <RiMenu3Line color="#B09547" size={27} onClick={() => setToggleMenu(true)} />}
        {toggleMenu && (
        <div className="gpt3__navbar-menu_container scale-up-center">
            <a onClick={() => setToggleMenu(false)} className="navbar-brand nav-content nav-content1 sentex2" href='#about'>About US</a>
            <a onClick={() => setToggleMenu(false)} className="navbar-brand nav-content nav-content1 sentex2" href='#ourpartner'>Our Partner</a>
            <a onClick={() => setToggleMenu(false)} className="navbar-brand nav-content nav-content1 sentex2" href='#ourservice'>Our Service</a>
            <a onClick={() => setToggleMenu(false)} className="navbar-brand nav-content nav-content1 sentex2" href='#ourprojects'>Our Project</a>
            <a onClick={() => setToggleMenu(false)} className="navbar-brand nav-content nav-content1 sentex2" href='#ourteam'>Our Team</a>
        </div>
        )}
      </div>
  
          
</nav>
 

</>

  </div>
  
    )
}
export default NavBar