import React from 'react'
import Row from 'react-bootstrap/esm/Row'
import homelogo from '../assets/Logo.png'

const Firstservice = () => {
  return (
    
        <Row className='w-100 mx-0'>
            <div id='ourservice' className='first-service'>
                
                <img src={homelogo}/>
                
                <p>Buy</p>
                <span>
                « Best time to buy is now »<br/>
We’ll help you estimate your budget range.
Future house can match you with a house you will want to call home.
                </span><p className='text-up-second'>Re-sell your home</p>
                <span>
                No matter what path you take to sell your home, future house can help you navigate a successful sale.
Future house can help you easily sell your home or apartment.
                </span>
                <a href='https://wa.me/1553382233'> 
                 <button className='first-service-button'>Contact Us

                </button>
                </a>

            </div>

        </Row>
    
  )
}

export default Firstservice