import React from 'react'
import logo13 from '../assets/logos/13.jpg'
import logo14 from '../assets/logos/14.png'
import logo15 from '../assets/logos/15.png'
import logo16 from '../assets/logos/16.jpg'
import logo17 from '../assets/logos/17.jpg'
import logo18 from '../assets/logos/18.jpg'

const ThirdCarouselImage = () => {
    return (
        <div className='first-divider-photo'>
            <img src={logo13}/>
            <img src={logo14}/>
            <img src={logo15}/>
            <img src={logo16}/>
            <img src={logo17}/>
            <img src={logo18}/>
    
        </div>
      )
}

export default ThirdCarouselImage